<template>
  <div class="headers">
    <!-- 折叠菜单按钮 -->
    <Hamburger/>
    <!-- 面包屑 -->
    <Bread class="Bread"/>
    <!-- 头部右侧菜单 -->
    <div class="right-menu">
      <FunCommon/>
      <router-link to="/projectScreen" title="大屏">
        <el-link :underline="false">
          <i class="el-icon-s-platform"></i>
        </el-link>
      </router-link>
      <!-- 下拉菜单-elementui组件 -->
      <Dropdown/>
    </div>
  </div>
</template>

<script>
import Hamburger from "./header/Hamburger";
import FunCommon from "./header/FunCommon";
import Dropdown from "./header/Dropdown";
import Bread from "./Bread";
export default {
  name: "Headers",
  components: {Bread, Dropdown, FunCommon, Hamburger}
}
</script>

<style scoped lang="less">
.headers {
  height: 64px;
  overflow: hidden;
  width: 100%;
  box-shadow: 0 0 5px #bbbbbb;
  background: #ffffff;
  // 面包屑样式
  .Bread {
    display: inline-block;
    height: 100%;
    line-height: 64px;
  }
  // 右侧样式
  .right-menu {
    float: right;
    height: 100%;

    &:focus {
      outline: none;
    }
    .el-icon-s-platform{
      font-size: 30px;
      position: relative;
      top:-15px;
      margin-right: 10px;
    }
  }
}

</style>