<template>
  <el-container class="home-container">
    <!-- 侧边栏 -->
    <div class="leftDiv">
      <Logo class="logo" />
      <el-aside class="el-menu-vertical-demo" :width="isCollapse ? '60px' : '230px'">
        <Sidebar class="menu" />
      </el-aside>
    </div>
    <!-- 页面主体区域 -->
    <el-container class="main-container">
      <!-- 头部区域 -->
      <el-header>
        <Headers />
        <!-- 个性化边框线 -->
        <div class="headersMain">
          <div class="leftBorder"></div>
          <div class="rightBorder"></div>
        </div>
      </el-header>
      <!-- 右侧内容主体 -->
      <el-main>
        <!-- tag标签组件 -->
<!--        <Tags />-->
        <!-- Main主体组件 -->
        <AppMain />
      </el-main>
    </el-container>
  </el-container>

</template>

<script>
import Logo from "../../components/layout/Logo";
// import Tags from "../../components/layout/Tags";
import Headers from "../../components/layout/Headers";
import Sidebar from "../../components/layout/Sidebar";
import AppMain from "./AppMain"
export default {
name: "layoutIndex",
  components: {AppMain, Sidebar, Headers, Logo},
  computed: {
    // 控制左侧菜单栏折叠状态
    isCollapse() {
      return this.$store.state.tab.isCollapse;
    },
  },
}
</script>

<style scoped lang="less">
/**滚动条的宽度，设置这个专门管控侧边栏的滚动条*/
::-webkit-scrollbar {
  width: 0px !important;
  height: 7px;
}
.home-container {
  height: 100%;
  background: #f0f0f0;
  overflow: hidden;
}

.el-header {
  .headersMain {
    display: flex;
    justify-content: space-between;
  }
  .leftBorder,
  .rightBorder {
    width: 30%;
  }
  .leftBorder {
    margin: -7px 0 0 15px;
  }
  .rightBorder {
    margin: -7px 15px 0 0;
    transform: rotate(180deg);
  }
}
.leftDiv {
  max-width: 230px;
  background:#f0f0f0;
  .logo {
    // margin-bottom: 6px;
  }
  .el-aside {
    transition: width 0.15s;
    -webkit-transition: width 0.15s;
    -moz-transition: width 0.15s;
    -o-transition: width 0.15s;
    height: calc(100% - 80px);
    overflow: hidden;
    overflow-y: scroll !important;
    .menu {
      height: 100%;
      overflow: auto;
    }
  }
}
.el-main {
  background-color: transparent;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

</style>