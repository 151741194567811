<template>
  <div class="logoDiv">
    <router-link to="/">
      <div v-show="!isCollapse" class="logo-title">
        <img :src="logoImg" alt="" />
        <span>{{compAlias }}</span>
      </div>
      <div v-show="isCollapse">
        <img :src="logoImgs" alt="" class="img2" />
      </div>
    </router-link>
  </div>
</template>

<script>
import logoImg from '@/assets/images/login_Img/lfLoge.png'
import logoImgs from '@/assets/images/login_Img/logos3.png'
export default {
  name: "Logo",
  computed: {
    // 控制左侧菜单栏logo状态
    isCollapse() {
      return this.$store.state.tab.isCollapse;
      // return false
    },
    compAlias(){
      return this.$store.state.common.user.compAlias 
    },
    logoImg(){
      return this.$store.state.common.user.compIcon || logoImg
    },
    logoImgs(){
      return this.$store.state.common.user.compIcon || logoImgs
    }
  },
}
</script>

<style scoped lang="less">
.logoDiv {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px;
  // margin-bottom: 6px;
  background: #008f4d;
  .img2 {
    max-height: 30px;
    width: 100%;
    text-align: center;
    max-width: 30px;
  }
  .logo-title{
    display: flex;
    // justify-content: center;
    // justify-content: left;
    align-items: center;
    // justify-content: center;
    color: #ffffff;
    span{
      padding-left: 10px;
      font-family: HuXiaoBoNanShenTi-2;
      text-shadow: 0px 5px 6px rgba(0,0,0,0.7);
    }
    img{
      width:100%;
    }
  }
}

</style>