<template>
  <Fragment>
    <template v-for="(group,index) in menuData">
      <!-- 代表有子级的菜单 -->
      <!-- 必须加v-if条件，否则调用会进入死循环，程序会崩 -->
      <el-submenu
          v-if="group.children && group.children.length > 0 && !group.hidden"
          :key="`${group.path}${index}`"
          :index="group.path">
        <!-- 多级菜单目录 -->
        <template slot="title">
          <!-- 图标 -->
          <i :class="['iconfont',group.meta.icon]"></i>
          <!-- 文本 -->
          <span slot="title">{{ group.meta.title }}</span>
        </template>
        <!-- 递归调用自己，通过如下name：MenuTree值 -->
        <MenuTree :menuData="group.children" class="child-item"></MenuTree>
      </el-submenu>
      <!-- 代表无子级的菜单，纯一级菜单 -->
      <el-menu-item
          v-if="(!group.children || group.children.length <= 0 ) && !group.hidden"
          :key="`${group.path}${index}`"
          :index="group.fullPath"
          @click="saveNavState(group.fullPath)">
        <!-- 图标 -->
        <i :class="['iconfont',group.meta.icon]"></i>
        <!-- 文本 -->
        <span slot="title">{{ group.meta.title }}</span>
       
      </el-menu-item>
    </template>
  </Fragment>
</template>

<script>
import {mapState} from "vuex";
import MenuTree from "./MenuTree";
import { Fragment } from 'vue-fragment'
export default {
  name: "MenuTree",
  components: {MenuTree, Fragment},
  props: {
    menuData: {
      type: Array,
      default: function () {
        return [];
      },
    },
  },
  data() {
    return {
      iconHeader: "iconfont jt-icon-",
      //   字体图标对象，一级菜单要用，根据后端传过来的节点id找到对应什么字符串（管理）来定义该用什么图标，字体图标资源来自assets/fonts下
    };
  },
  created() {
  },
  computed: {
    // 控制左侧菜单栏文本及图标折叠状态
    // 通过扩展运算符映射Vuex全局的左侧权限菜单
    ...mapState({
      activePath: state => state.common.activePath
    })
  },
  methods: {
    // 保存被点击的路由链接激活状态
    saveNavState(activePath) {
      this.$store.dispatch('common/setActivePath',activePath)
    },
  },
}
</script>

<style scoped lang="less">
.iconfont{
  font-size: 16px;
  font-weight: bold;
  margin-right: 10px;
}
.icon-huanbaochufa-5,.icon-jiashiyuandanganguanli{
  font-size: 18px;
}
.icon-xitongquanxian{
  font-size: 14px;
}

</style>