<template>
  <div class="hButton">
    <el-button
        :class="{'iconfont icon-icon-caidanshouqi': !isCollapse, 'iconfont icon-icon-caidanzhankai': isCollapse,}"
        size="mini"
        :collapse="isCollapse"
        @click="handleMenu">
    </el-button>
  </div>
</template>

<script>
export default {
  name: "Hamburger",
  data() {
    return {};
  },
  created() {},
  methods: {
    // 控制左侧菜单栏折叠展开
    handleMenu() {
      this.$store.commit("tab/collapseMenu"); //采用路径形式调用，因为那边加锁了
    },
  },
  computed: {
    // 控制左侧菜单栏折叠状态
    isCollapse() {
      return this.$store.state.tab.isCollapse;
    },
  },
}
</script>

<style scoped lang="less">
.hButton {
  height: 64px;
  float: left;
  display: flex;
  align-items: center;
  justify-content: center;
  .el-button {
    border: none;
    background: none;
    font-size: 25px;
  }
  .el-button:hover {
    // 定义动画对象及过渡时间
    animation-name: example;
    animation-duration: 5s;
  }
  // 设置动画属性
  @keyframes example {
    from {
      color: #fff;
    }
    to {
      color: #000000;
    }
  }
}
/deep/.el-button:focus, .el-button:hover {
    color: #008f4d;
    border-color: #c6e2ff;
    background-color: #ecf5ff;
}
</style>