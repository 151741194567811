<template>
  <div class="sidebar">
    <!-- 根据后台传过来的菜单参数动态渲染前端左侧菜单栏 -->
    
    <el-menu
        background-color="#ffffff"
        class="leftMenu el-menu-vertical-demo"
        text-color="#000"
        active-text-color="#3ca777"
        :unique-opened="true"
        :collapse="isCollapse"
        :collapse-transition="false"
        :router="true"
        :default-active="activePath">
      <!-- 首页所有角色用户都能看到 -->
<!--      <el-menu-item index="welcome" @click="saveNavState('welcome')">-->
<!--        &lt;!&ndash; 图标 &ndash;&gt;-->
<!--        <i class="el-icon-s-home"></i>-->
<!--        &lt;!&ndash; 文本 &ndash;&gt;-->
<!--        <span slot="title">首页</span>-->
<!--      </el-menu-item>-->
      <!-- 引入组件，动态菜单栏 -->
      <MenuTree :menuData="menulist"></MenuTree>
    </el-menu>
  </div>
</template>

<script>
import {mapState} from "vuex";
import MenuTree from "./MenuTree";
// import constantRoutes from '../../router/routerConfig'
// 字体图标统一前缀
export default {
  name: "Sidebar",
  components: {MenuTree},
  data() {
    return {
      // 左侧菜单数据
      menulist: [],
      // isCollapse: true
    };
  },
  // 初始化
  created() {
    // 动态的获取导航信息，还未做
    // this.menulist = constantRoutes[1].children
  
    
    this.menulist = this.staffRole
  },
  mounted() {
    // 将当前页面做为 默认 default-active
    this.$store.dispatch('common/setActivePath',this.$route.fullPath)
  },
  // 计算属性
  computed: {
    // 控制左侧菜单栏文本及图标折叠状态
    isCollapse() {
      return this.$store.state.tab.isCollapse;
    },
    // menulist(){
    //   return this.$store.state.common.staffRole;
    // },
    // 通过扩展运算符映射Vuex全局的左侧权限菜单
    ...mapState({
      activePath: state => state.common.activePath,
      staffRole: state => state.common.staffRole
    })
  },
  // 事件方法
  methods: {
    // 保存被点击的路由链接激活状态
    saveNavState(activePath) {
      // 将被点击的路由存入vuex
      this.$store.dispatch('common/setActivePath',activePath)
    },
  },
}
</script>

<style scoped lang="less">
/deep/ .el-menu-item:hover {
    background-color:#f3fffb !important; /* Change this color to the desired background color */
}
</style>