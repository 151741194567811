<template>
  <el-dropdown class="avatar-container" trigger="hover">
    <div class="avatar-wrapper">
<!--      <i class="el-icon-user-solid"></i>-->
      <img src="@/assets/images/head.gif" class="user-avatar" />
      <span class="name">{{ user.staffName || '管理员' }}</span>
      <i class="el-icon-caret-bottom" />
    </div>
    <el-dropdown-menu
        slot="dropdown"
        class="user-dropdown"
        style="margin-right: -13px">
<!--      <router-link to="/projectScreen">-->
<!--        <el-dropdown-item>-->
<!--          <el-link :underline="false"><i class="iconfont jt-icon-zhuye">&nbsp;&nbsp;大屏</i></el-link>-->
<!--        </el-dropdown-item>-->
<!--      </router-link>-->
      <!-- <router-link to="/view/person">
        <el-dropdown-item>
          <el-link :underline="false"><i class="iconfont jt-icon-gerenzhongxin">&nbsp;&nbsp;个人信息</i></el-link>
        </el-dropdown-item>
      </router-link> -->
      <el-dropdown-item divided @click.native="logout">
        <el-link :underline="false"><i class="iconfont jt-icon-tuichudenglu">&nbsp;&nbsp;<span class="logOutName">退出登录</span></i></el-link>
      </el-dropdown-item>
    </el-dropdown-menu>
  </el-dropdown>
</template>

<script>
import { mapState } from "vuex";
import login from "../../../api/modules/login";
export default {
  name: "Dropdown",
  data() {
    return {};
  },
  created() { },
  methods: {
    // 退出登录
    async logout() {
      // 弹框询问用户是否删除数据
      const confirmResult = await this.$confirm(
          "你是否要退出登录?",
          "系统提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          }
          // 捕获后台返回的异常，并根据该异常继续往下业务逻辑判断👇
      ).catch((error) => error);
      // 如果用户确认退出，则返回值为字符串 confirm
      // 如果用户取消退出，则返回值为字符串 cancel
      if (confirmResult !== "confirm") {
        this.$message.info("已取消退出");
      }else {
        // await logout();
        login.logout()
        this.$router.push('/login')
      }
    },
  },
  // 计算属性
  computed: {
    ...mapState({
      user: state => state.common.user,
    })
  },
}
</script>

<style scoped lang="less">
.avatar-container {
  margin-right: 30px;
  .avatar-wrapper {
    font-size: 14px;
    height: 64px;
    display: flex;
    justify-content: center;
    align-items: center;
    // 头像和下拉菜单样式 start
    .user-avatar {
      cursor: pointer;
      width: 40px;
      height: 40px;
      border-radius: 50%;
    }
    .name {
      vertical-align: middle;
      margin-top: -3px;
      margin-left: 5px;
      color: #000;
    }
    .name:hover {
      cursor: pointer;
    }
    .el-icon-caret-bottom {
      cursor: pointer;
      position: absolute;
      right: -15px;
      top: 23.5px;
      color: #000;
    }
  }
}

</style>